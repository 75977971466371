import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

    NavigationExtras: any;
	postData: any;
	addeditems: Array<any> = [];
	items: Array<any> = [];
    fruits: Array<any> = [];
	itemupdate: any = 0;
	 
  constructor(private router: Router,private route: ActivatedRoute,private http: HttpClient,private SpinnerService: NgxSpinnerService) { 
	this.route.queryParams.subscribe(params => {	
		localStorage.removeItem("products");
	
		 this.SpinnerService.show(); 
		 this.http.get<any>('https://www.knkkarts.com/occasions/all?id='+params.product_id).subscribe((response) => {
			
			this.items=[];
			console.log(response);
			for(var i=0; i<response.length;i++){
				this.items.push({id: response[i]['id'],price: response[i]['price'],description: response[i]['description'], name: response[i]['name'], photo: response[i]['photo']});
			}
			localStorage.setItem("products", JSON.stringify(this.items));
			 this.SpinnerService.hide(); 
			//this.items = response;
			/*this.items = [];
			for(var i=0; i<response.products.length;i++){
				this.items.push({price: response.products[i]['price'], name: response.products[i]['name']});
			}*/
			
		});
	});
	//});
  }
     viewitem(id) {
	    this.postData = {"product_id":id} 
		this.NavigationExtras = {
		    queryParams:this.postData
		};
		this.router.navigate(['product'], this.NavigationExtras); 
   }
  
   
  updateitem(id,name,quantity,price) {

		
		var result;
		for( var i = 0, len = this.fruits.length; i < len; i++ ) {
			if( this.fruits[i].id === id ) {
				this.fruits[i].quantity=this.fruits[i].quantity+quantity;
				this.fruits[i].price=this.fruits[i].price*quantity;
				result = this.fruits[i];
				break;
			}
		}
		//console.log(result);
		if(!(result)){
			this.fruits.push({"id":id,"name":name,"quantity":quantity,"price":price});
		}
		

		sessionStorage.setItem("addedproducts", JSON.stringify(this.fruits));
		this.router.navigate(['/cart']);
	}

  ngOnInit() {
	  if(sessionStorage.getItem("addedproducts")!=null){
			this.fruits = JSON.parse(sessionStorage.getItem("addedproducts"));
		}
  }

}

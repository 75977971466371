import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';  
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
	addeditems: Array<any> = [];
	items: Array<any> = [];
    fruits: Array<any> = [];
	 itemupdate: any = 0;
  angForm: FormGroup;  
  constructor(private fb: FormBuilder,private router: Router,private SpinnerService: NgxSpinnerService) {  
    this.createForm();  
	 
  }  
  createForm() {  
    this.angForm = this.fb.group({  
      address1: ['', [Validators.required,Validators.minLength(3)] ],  
      address2: ['', [Validators.required,Validators.minLength(3)] ],  
      mobile: ['', [Validators.required,Validators.minLength(10)] ],  
      email: ['', [Validators.required,Validators.minLength(3), Validators.email] ], 
	  city: ['', [Validators.required,Validators.minLength(3)] ],  
      postalcode: ['', [Validators.required,Validators.minLength(6)] ],  	  
    });  
  } 

  ngOnInit() {
	    if(sessionStorage.getItem("addedproducts")!=null ){
			this.fruits = JSON.parse(sessionStorage.getItem("addedproducts"));
			console.log('--------------------------------------------------------------------------------------------');
			console.log(this.fruits);
			if(this.fruits.length==0){
				alert('Add products to cart');
				this.router.navigate(['/']);
			}
		}
		
		
		this.itemupdate=0;
  }
	updateitem(id,name,quantity,price) {

		
		var result;
		for( var i = 0, len = this.fruits.length; i < len; i++ ) {
			if( this.fruits[i].id === id ) {
				this.fruits[i].quantity=this.fruits[i].quantity+quantity;
				this.fruits[i].price=this.fruits[i].price*quantity;
				result = this.fruits[i];
				break;
			}
		}
		//console.log(result);
		if(!(result)){
			this.fruits.push({"id":id,"name":name,"quantity":quantity,"price":price});
		}
		sessionStorage.setItem("addedproducts", JSON.stringify(this.fruits));
	}
	decreaseitem(id,name,quantity,price) {

		
		var result;
		var found=0;
		for( var i = 0, len = this.fruits.length; i < len; i++ ) {
			if( this.fruits[i].id === id ) {
				if(this.fruits[i].quantity>1){
					this.fruits[i].quantity=this.fruits[i].quantity-quantity;
					this.fruits[i].price=this.fruits[i].price*quantity;
					result = this.fruits[i];
					found=1;
					break;
				}
			}
		}
		//console.log(result);
		if(!(result) && found==1){
			this.fruits.push({"id":id,"name":name,"quantity":quantity,"price":price});
		}

		sessionStorage.setItem("addedproducts", JSON.stringify(this.fruits));
		
	}

	removeitem(name,quantity) {
		var result;
		for( var i = 0, len = this.fruits.length; i < len; i++ ) {
			if( this.fruits[i].name === name ) {
				this.fruits.splice(i,1);
				break;
			}
		}
		sessionStorage.setItem("addedproducts", JSON.stringify(this.fruits));
		//alert(this.fruits.length);
		if(this.fruits.length==0){
				alert('Add products to cart');
				this.router.navigate(['/']);
			}
	}
	
	
	
}

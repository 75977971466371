import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
    
	addeditems: Array<any> = [];
	items: Array<any> = [];
	item: Array<any> = [];
    fruits: Array<any> = [];
    itemupdate: any = 0;
	NavigationExtras: any;
	postData: any;
	
  constructor(private router: Router,private route: ActivatedRoute) {
	  this.route.queryParams.subscribe(params => {
		
		 if(localStorage.getItem("products")!=null){
			this.items = JSON.parse(localStorage.getItem("products"));
			for(var i=0; i<this.items.length;i++){
				if(this.items[i]['id']==params.product_id){
					 this.item.push(this.items[i]);
				}
			}
			console.log(this.item);
		 }
	  });
		 
	 }

  ngOnInit() {
		//console.log('--------------------------------------------------'+localStorage.getItem("products"));
		
		if(sessionStorage.getItem("addedproducts")!=null){
			this.fruits = JSON.parse(sessionStorage.getItem("addedproducts"));
		}
		
		this.itemupdate=0;
		
  }

	additem() {
	  this.fruits.push({"name":"Kiwi","quantity":1,"price":1});
	  sessionStorage.setItem("addedproducts", JSON.stringify(this.fruits));
	}

	myFunction2(item, index) {
	  console.log('Index:'+index);
	  console.log('Name:'+item.name);
	  console.log('Quantity:'+item.quantity);
	  console.log('Price:'+item.price);
	  console.log('-----------------------');
	}
	
	updateitem(id,name,quantity,price) {

		
		var result;
		for( var i = 0, len = this.fruits.length; i < len; i++ ) {
			if( this.fruits[i].id === id ) {
				this.fruits[i].quantity=this.fruits[i].quantity+quantity;
				this.fruits[i].price=this.fruits[i].price*quantity;
				result = this.fruits[i];
				break;
			}
		}
		//console.log(result);
		if(!(result)){
			this.fruits.push({"id":id,"name":name,"quantity":quantity,"price":price});
		}
		this.fruits.forEach(this.myFunction2);

		sessionStorage.setItem("addedproducts", JSON.stringify(this.fruits));
		this.router.navigate(['/cart']);
	}
	decreaseitem(name,quantity,price) {

		
		var result;
		var found=0;
		for( var i = 0, len = this.fruits.length; i < len; i++ ) {
			if( this.fruits[i].name === name ) {
				if(this.fruits[i].quantity>1){
					this.fruits[i].quantity=this.fruits[i].quantity-quantity;
					this.fruits[i].price=this.fruits[i].price*quantity;
					result = this.fruits[i];
					found=1;
					break;
				}
			}
		}
		//console.log(result);
		if(!(result) && found==1){
			this.fruits.push({"name":name,"quantity":quantity,"price":price});
		}
		this.fruits.forEach(this.myFunction2);
		
			console.log('--------------------------------------------------------------------------------------------');
			
		console.log(this.fruits);
		sessionStorage.setItem("addedproducts", JSON.stringify(this.fruits));
		
	}

	removeitem(name,quantity) {

		var result;
		for( var i = 0, len = this.fruits.length; i < len; i++ ) {
			if( this.fruits[i].name === name ) {
				this.fruits.splice(i,1);
				break;
			}
		}
		sessionStorage.setItem("addedproducts", JSON.stringify(this.fruits));
		this.fruits.forEach(this.myFunction2);
		console.log('--------------------------------------------------------------------------------------------');
	}

}

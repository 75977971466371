import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';  
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

angForm: FormGroup;  
loading = false;
    submitted = false;
    returnUrl: string;
	
  constructor(private fb: FormBuilder,private router: Router,private http: HttpClient) {  
    this.createForm();  
  }  
  createForm() {  
    this.angForm = this.fb.group({  
      UserName: ['', [Validators.required,Validators.minLength(3)] ],  
      Upassword: ['', [Validators.required,Validators.minLength(3)] ],  
      
    });  
  } 
  checkuser(username,upassword){
	  
	  
	    this.http.post('http://sibia.ca/users/mobilelogin',{username:username,password:upassword}).subscribe((response) => {
		/*	if(response.logged_in=='true'){
				 sessionStorage.setItem("logged_in", 'logged_in');
				 sessionStorage.setItem("user_id", response.user_id);
			}*/
		});
	  
	  console.log(username);	  
	  console.log(upassword);
		//this.router.navigate(['/']);	  
  }
  //token
	setToken(token: string) {
		localStorage.setItem('token', token);
	}

	getToken() {
		return localStorage.getItem('token');
	}

	deleteToken() {
		localStorage.removeItem('token');
	}

	isLoggedIn() {
		const usertoken = this.getToken();
		if (usertoken != null) {
		return true
		}
		return false;
	}
  
  ngOnInit() { 
	console.log(this.angForm.controls);
  }  
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from "ngx-spinner";  

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { CategoryComponent } from './category/category.component';
import { ProductsComponent } from './products/products.component';
import { ProductComponent } from './product/product.component';
import { OrdersComponent } from './orders/orders.component';
import { CartComponent } from './cart/cart.component';
import { AddOrderComponent } from './add-order/add-order.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { LogoutComponent } from './logout/logout.component';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';  
import { ReactiveFormsModule } from '@angular/forms';  
import { HttpClientModule, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';  
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@NgModule({
  declarations: [
    AppComponent,
    AboutusComponent,
    ContactusComponent,
    CategoryComponent,
    ProductsComponent,
    ProductComponent,
    OrdersComponent,
    CartComponent,
    AddOrderComponent,
    LoginComponent,
    ProfileComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
	SlimLoadingBarModule,
	ReactiveFormsModule,
	HttpClientModule ,
	Ng2SearchPipeModule,
	NgxSpinnerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { CategoryComponent } from './category/category.component';
import { ProductsComponent } from './products/products.component';
import { ProductComponent } from './product/product.component';
import { OrdersComponent } from './orders/orders.component';
import { CartComponent } from './cart/cart.component';
import { AddOrderComponent } from './add-order/add-order.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  {  
    path: 'about-us',  
    component: AboutusComponent  
  },
  {  
    path: 'contact-us',  
    component: ContactusComponent  
  },
  {  
    path: 'products',  
    component: ProductsComponent  
  },
  {  
    path: 'product',  
    component: ProductComponent  
  },
  {  
    path: 'category/:id',  
    component: CategoryComponent  
  },
  {  
    path: 'login',  
    component: LoginComponent  
  },
  {  
    path: 'logout',  
    component: LogoutComponent  
  },
  {  
    path: 'profile',  
    component: ProfileComponent  
  },
  {  
    path: 'cart',  
    component: CartComponent  
  },
  {  
    path: 'add-order/',  
    component: AddOrderComponent  
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
